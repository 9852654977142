import React from "react";
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";

import { TypeB } from "components/callouts";
import bivalirudinBoxImg from "images/home/bivalirudin-box-and-vial.png";
import dexmedBoxImg from "images/home/dexmed-pole-image.png";
import ephedrineBoxImg from "images/home/ephedrine-sulfate-injection-bottle.svg";
import prevduoBoxImg from "images/home/prevduo-box-and-syringe.png";
import vasostrictBoxImg from "images/home/vasostrict-bottles.svg";

import "./products.scss";

const Reference = (
    <>
        <p>
            <strong>References: 1.</strong> Data on file. DOF-VS-02. Par Pharmaceutical, Inc.; September 21, 2023. <strong>2.</strong> Data on file. DOF-PRVD-01. Par Pharmaceutical, Inc.; July 20, 2023. <strong>3.</strong> Data on file. DOF-EH-01. Par Pharmaceutical, Inc.; July 31, 2023. <strong>4.</strong> Data on file. DOF-DX-01. Par Pharmaceutical, Inc.; July 27, 2023. <strong>5.</strong> Healthcare Distribution Alliance. Standard pharmaceutical product and medical device information: Bivalirudin Injection. July 10, 2023. <strong>6.</strong> Vasostrict<sup>®</sup>. Prescribing Information. Par Pharmaceutical, Inc. <strong>7.</strong> PREVDUO<sup>®</sup>. Prescribing Information. Par Pharmaceutical, Inc. <strong>8.</strong> Ephedrine Sulfate Injection. Prescribing Information. Par Pharmaceutical, Inc. <br className="show-desktop"/><strong>9.</strong> Dexmedetomidine Hydrochloride Injection. Prescribing Information. Par Pharmaceutical, Inc. <strong>10.</strong> Bivalirudin Injection. Prescribing Information. Par Pharmaceutical, Inc. <strong>11.</strong> The United States Pharmacopeial Convention. USP compounding standards and beyond-use-dates. https://go.usp.org/2022_Revisions_795_797. Accessed November 8, 2023.
        </p>
    </>
)

function ProductsPage({ pageContext }) {
    return (
        <Layout
            noShowStickyIsi
            pageClass="products"
            reference={Reference}
            breadcrumbData={pageContext.breadcrumb}
        >
            <Seo pageTitle="Products" pageDescription="Explore the TruDelivery™ ready-to-use product portfolio" />
            <section className="first">
                <div className="centered-content">
                    <Row>
                        <Col xs={12}>
                            <h1 className="text-blue">Explore the growing portfolio of <br className="show-desktop" />TruDelivery<sup>&reg;</sup> ready-to-use (RTU) products</h1>
                            <h2>Ready when you need it, longer<sup>1-5</sup>*</h2>

                            <div className="block-content">
                                <p>TruDelivery<sup>&reg;</sup> RTU products require no compounding, diluting, or mixing<sup>6-10</sup> and provide extended dating compared with compounded products.<sup>1-5,11</sup>*</p>
                                <p>Most hospital pharmacy buyers can purchase TruDelivery<sup>&reg;</sup> RTU products with their routine daily wholesale orders. These TruDelivery<sup>&reg;</sup> products are already available through your health system’s full-line wholesaler and on your group purchasing organization (GPO) contract.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="block-product-details gray-bg">
                <div className="centered-content">
                    <div className="block-title">
                        <h2 className="txt-blue txt-center">Product Details</h2>
                    </div>

                    <Row justify='between'>
                        <Col xl={3.75} lg={4} md={6}>
                            <TypeB
                                ctaTitle="Bivalirudin Injection"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={bivalirudinBoxImg}
                                ctaImageAlt='Bivalirudin injection ready-to-use bottles'
                                pdfLink='https://www.parpharm.com/pdfs/catalog/sterile/v2_Bivalirudin_Injection_PI_04-2023.pdf'
                                buttonText="View Product"
                                buttonURL="/products/bivalirudin-injection"
                            />
                        </Col>

                        <Col xl={3.75} lg={4} md={6}>
                            <TypeB
                                className='dexmed-img'
                                ctaTitle="Dexmedetomidine HCl Injection"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={dexmedBoxImg}
                                ctaImageAlt='Dexmedetomidine HCI injection ready-to-use bottles'
                                pdfLink='https://www.endodocuments.com/documents/Dexmedetomidine/Dexmedetomidine_PI.pdf'
                                buttonText="View Product"
                                buttonURL="/products/dexmedetomidine-hcl"
                                captionClass='img-caption__right'
                            />
                        </Col>

                        <Col xl={3.75} lg={4} md={6}>
                            <TypeB
                                ctaTitle="Ephedrine Sulfate<br class='show-desktop' /> Injection"
                                ctaSubTitle="Premixed vials"
                                ctaImage={ephedrineBoxImg}
                                ctaImageAlt='Ephedrine sulfate injection premixed vials'
                                pdfLink='https://www.endodocuments.com/documents/Ephedrine/Ephedrine_5_PI.pdf'
                                buttonText="View Product"
                                buttonURL="/products/ephedrine-sulfate-injection"
                                captionClass='img-caption__right img-caption__rightAlt'
                            />
                        </Col>

                        <Col xl={3.75} lg={4} md={6}>
                            <TypeB
                                ctaTitle="PREVDUO<sup>&reg;</sup> (neostigmine  methylsulfate and  glycopyrrolate) Injection"
                                ctaSubTitle="Prefilled syringes"
                                ctaImage={prevduoBoxImg}
                                ctaImageAlt='Prevduo carton and prefilled syringe'
                                pdfLink='https://www.parpharm.com/pdfs/catalog/sterile/PREVDUO_PI_02-2023.pdf'
                                buttonText="View Product"
                                buttonURL="/products/prevduo-injection"
                                captionClass='img-caption__right'
                                className='pb-lg-0'
                            />
                        </Col>

                        <Col xl={3.75} lg={4} md={6}>
                            <TypeB
                                ctaTitle="Vasostrict<sup>&reg;</sup><br />(Vasopressin Injection, USP)"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={vasostrictBoxImg}
                                ctaImageAlt='Vasostrict ready-to-use bottles'
                                pdfLink='https://www.endodocuments.com/documents/Vasostrict/Vasostrict_PI.pdf'
                                buttonText="View Product"
                                buttonURL="/products/vasostrict-injection"
                                captionClass='img-caption__right'
                                className='pb-lg-0'
                            />
                        </Col>

                        <Col xl={3.75} lg={4} md={6}>
                            <div className="block-content">
                                <h2 className="txt-blue txt-center">New RTU product coming soon in 2024.</h2>
                            </div>
                        </Col>
                    </Row>

                </div>
            </section>

            <section className="block-footnotes">
                <div className="centered-content">
                    <div className="footnotes">
                        <div className="footnotes-wrap">
                            <span className='symbol'>*</span>
                            <p className="footnotes-symbols">For Category 3 controlled sterile preparations (CSP), the maximum beyond-use date (BUD) is 90 days at controlled room temperature (CRT), 120 days in refrigerator, and 180 days in freezer<sup>11</sup> versus shelf life of 2 years at CRT for Ephedrine, PREVDUO<sup>®</sup>, and Dexmedetomidine<sup>2-4</sup>; 12 months at CRT and 2 years in refrigerator for Vasostrict<sup>®1</sup>; 72 hours at CRT and 18 months in refrigerator for Bivalirudin.<sup>5,10</sup></p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default ProductsPage;
